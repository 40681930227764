import loadable from '@loadable/component';

export const LoadableIcons = {
  Cart: loadable(() => import('Atoms/Icons/CartIcon')),
  Chevron: loadable(() => import('Atoms/Icons/ChevronIcon')),
  Close: loadable(() => import('Atoms/Icons/CloseIcon')),
  Heart: loadable(() => import('Atoms/Icons/HeartIcon')),
  House: loadable(() => import('Atoms/Icons/HouseIcon')),
  Checkmark: loadable(() => import('Atoms/Icons/CheckmarkIcon')),
  Bankid: loadable(() => import('Atoms/Icons/BankidIcon')),
  CreditCard: loadable(() => import('Atoms/Icons/CreditCardIcon')),
  User: loadable(() => import('Atoms/Icons/UserIcon')),
  Add: loadable(() => import('Atoms/Icons/AddIcon')),
  Subtract: loadable(() => import('Atoms/Icons/SubtractIcon')),
  Back: loadable(() => import('Atoms/Icons/BackIcon')),
  Search: loadable(() => import('Atoms/Icons/SearchIcon')),
  Filters: loadable(() => import('Atoms/Icons/FiltersIcon')),
  Arrow: loadable(() => import('Atoms/Icons/ArrowIcon')),
  Storage: loadable(() => import('Atoms/Icons/StorageIcon')),
  Wrench: loadable(() => import('Atoms/Icons/WrenchIcon')),
  Shipment: loadable(() => import('Atoms/Icons/ShipmentIcon')),
  Remove: loadable(() => import('Atoms/Icons/RemoveIcon')),
  Info: loadable(() => import('Atoms/Icons/InfoIcon')),
  Return: loadable(() => import('Atoms/Icons/ReturnIcon')),
  Wheel: loadable(() => import('Atoms/Icons/WheelIcon')),
  Exhaust: loadable(() => import('Atoms/Icons/ExhaustIcon')),
  Breaks: loadable(() => import('Atoms/Icons/BreaksIcon')),
  Chain: loadable(() => import('Atoms/Icons/ChainIcon')),
  Fuse: loadable(() => import('Atoms/Icons/FuseIcon')),
  Motorcycle: loadable(() => import('Atoms/Icons/MotorcycleIcon')),
  Motorcycle2: loadable(() => import('Atoms/Icons/Motorcycle2Icon')),
  Electric: loadable(() => import('Atoms/Icons/ElectricIcon')),
  Suspension: loadable(() => import('Atoms/Icons/SuspensionIcon')),
  Engine: loadable(() => import('Atoms/Icons/EngineIcon')),
  EngineFilters: loadable(() => import('Atoms/Icons/EngineFiltersIcon')),
  FrameChassi: loadable(() => import('Atoms/Icons/FrameChassiIcon')),
  Styling: loadable(() => import('Atoms/Icons/StylingIcon')),
  PlasticParts: loadable(() => import('Atoms/Icons/PlasticPartsIcon')),
  Oil: loadable(() => import('Atoms/Icons/OilIcon')),
  Bag: loadable(() => import('Atoms/Icons/BagIcon')),
  Tshirt: loadable(() => import('Atoms/Icons/TshirtIcon')),
  Truck: loadable(() => import('Atoms/Icons/TruckIcon')),
  Fullscreen: loadable(() => import('Atoms/Icons/FullscreenIcon')),
  Card: loadable(() => import('Atoms/Icons/CardIcon')),
  Support: loadable(() => import('Atoms/Icons/SupportIcon')),
  Finance: loadable(() => import('Atoms/Icons/FinanceIcon')),
  HamburgerMenu: loadable(() => import('Atoms/Icons/HamburgerMenuIcon')),
  Share: loadable(() => import('Atoms/Icons/ShareIcon')),
  Email: loadable(() => import('Atoms/Icons/EmailIcon')),
  Eye: loadable(() => import('Atoms/Icons/EyeIcon')),
  EyeClosed: loadable(() => import('Atoms/Icons/EyeClosedIcon')),
  Document: loadable(() => import('Atoms/Icons/DocumentIcon')),
  Logo: loadable(() => import('Atoms/Icons/LogoIcon')),
  DIGasellIcon: loadable(() => import('Atoms/Icons/DIGasellIcon')),
};

export type KexIconType = keyof typeof LoadableIcons;

const KexIconLoader = (icon: KexIconType) => LoadableIcons[icon];

export default KexIconLoader;
